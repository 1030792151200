import { getAnalytics, logEvent } from "firebase/analytics"
import {
  GoogleAuthProvider,
  OAuthProvider,
  createUserWithEmailAndPassword,
  getAdditionalUserInfo,
  getAuth,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth"
import { getHost } from "hooks/misc/useHost"

export const startLoginWithEmail = async (email: string, password: string) => {
  await signInWithEmailAndPassword(getAuth(), email, password)
  logEvent(getAnalytics(), "login", { method: "Email" })
}

export const startLoginWithGoogle = async () => {
  getAuth().languageCode = "de"
  try {
    const googleAuthProvider = new GoogleAuthProvider()
    const result = await signInWithPopup(getAuth(), googleAuthProvider)

    const additionalUserInfo = getAdditionalUserInfo(result)

    // If the user is a new user
    if (additionalUserInfo?.isNewUser) {
      logEvent(getAnalytics(), "sign_up", { method: "Google" })
    }
    logEvent(getAnalytics(), "login", { method: "Google" })
  } catch (error) {
    console.error(error)
  }
}

export const startLoginWithMicrosoft = async () => {
  getAuth().languageCode = "de"
  try {
    const microsoftAuthProvider = new OAuthProvider("microsoft.com")
    const result = await signInWithPopup(getAuth(), microsoftAuthProvider)

    const additionalUserInfo = getAdditionalUserInfo(result)

    // If the user is a new user
    if (additionalUserInfo?.isNewUser) {
      logEvent(getAnalytics(), "sign_up", { method: "Microsoft" })
    }
    logEvent(getAnalytics(), "login", { method: "Microsoft" })
  } catch (error) {
    console.error(error)
  }
}

export const startLoginWithApple = async () => {
  getAuth().languageCode = "de"
  try {
    const provider = new OAuthProvider("apple.com")
    provider.addScope("email")
    provider.addScope("name")
    provider.setCustomParameters({
      locale: "de",
    })
    const result = await signInWithPopup(getAuth(), provider)
    console.log("Apple result", result)

    const additionalUserInfo = getAdditionalUserInfo(result)
    console.log("Apple additional", additionalUserInfo)

    // If the user is a new user
    if (additionalUserInfo?.isNewUser) {
      logEvent(getAnalytics(), "sign_up", { method: "Apple" })
    }
    logEvent(getAnalytics(), "login", { method: "Apple" })
  } catch (error) {
    console.error(error)
  }
}

export const startSignUpWithEmail = async (newUser: { email: string; password: string }, shopId: string) => {
  try {
    getAuth().languageCode = "de"

    const result = await createUserWithEmailAndPassword(getAuth(), newUser.email, newUser.password)
    const user = result.user

    const { origin } = getHost()

    const actionCodeSettings = {
      url: `${origin}/${shopId}`,
    }
    await sendEmailVerification(user, actionCodeSettings)
    logEvent(getAnalytics(), "sign_up", { method: "Email" })
    logEvent(getAnalytics(), "login", { method: "Email" })

    //Check if userdoc got created
  } catch (error) {
    console.error(error)
  }
}
