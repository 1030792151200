import LoginButton from "components/utils/loginButtons/LoginButton"
import getpackedIcon from "images/login/getpacked.svg"

interface GetpackedLoginButtonProps {
  onClick: () => void
  disabled?: boolean
  signUp?: boolean
  reset?: boolean
  loading?: boolean
}
const GetpackedLoginButton = ({ onClick, disabled, signUp, reset, loading }: GetpackedLoginButtonProps) => {
  return (
    <LoginButton
      disabled={disabled}
      icon={getpackedIcon}
      text={
        loading
          ? "Dein Shop ist gleich soweit"
          : signUp
            ? "Jetzt registrieren"
            : reset
              ? "Passwort zurücksetzen"
              : "Jetzt anmelden"
      }
      onClick={onClick}
    />
  )
}

export default GetpackedLoginButton
